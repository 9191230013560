import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/layout';
import Content from './components/content';
import Login from "./components/login";
import FormWrapper from "./components/common/form-wrapper";
import SignUp from "./components/signup";

const App = () => {
  const AuthData = localStorage.getItem('fincartLoginData')

  if(AuthData) {
    // eslint-disable-next-line no-undef
    window.location.href = `${process.env.REACT_APP_MERCHANT_URL}/app`
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Content />} />
          <Route path="/login" element={<FormWrapper Form={Login} />} />
          <Route path="/get-started" element={<FormWrapper Form={SignUp} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App