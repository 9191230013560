/* eslint-disable no-undef */
import React, { useCallback, useMemo, useState, useEffect } from "react"
import { useForm } from "react-hook-form"

import FormField from "../common/form-field"
import defaultLocale from "../../defaultLocal"
import { getFormFields } from "./utils"
import { client } from "../../utils/client"
import LoadingIcon from "../../assets/icons/LoadingIcon"

const Login = ({ isSmallScreen }) => {
  const { register, handleSubmit, formState } = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { errors, isValid, isValidating, isSubmitSuccessful } = formState

  const formClassName = 'md:bg-white bg-transparent border-light-grey-outline md:shadow-login-card rounded-xl px-[25px] w-full max-w-full md:max-w-[380px]'

  const formFields = useMemo(() => getFormFields({ isPasswordShown, setIsPasswordShown }), [isPasswordShown])
  const isLoginButtonDisabled = useMemo(() => isSubmitting || !isValid, [isSubmitting, isValid])

  const { login: { welcomeBackText, loginButton } } = defaultLocale

  useEffect(() => {
    if(isSubmitSuccessful, isValidating && errorMsg){
      setErrorMsg(null)
    }
  }, [isValidating, isSubmitSuccessful, errorMsg])

  const login = (email, password) => {
    setIsSubmitting(true)
    return client.post({
      path: '/auth/login',
      data: { email, password },
      config: {
        successCallBack: (result) => {
          const fincartInfo = JSON.stringify(result?.data?.body || {})
          const encodedToken = encodeURIComponent(fincartInfo);
          window.location.href = `${process.env.REACT_APP_MERCHANT_URL}?data=${encodedToken}`
          setIsSubmitting(false)
        },
        errorCallBack: (error) => {setErrorMsg(error); setIsSubmitting(false);}
      }
    })
  };

  const onSubmit = async ({ email, password }) => {
    await login(email, password)
  };

  const getSubmitButtonClassName = useCallback(
    () => (!isLoginButtonDisabled? "w-full mt-5 bg-blue text-white font-Poppins-Bold h-9 rounded-full" : "w-full mt-5 bg-blue opacity-40 text-white font-Poppins-Bold h-9 rounded-full"),
    [!isLoginButtonDisabled],
  )

  const getFormClassName = useCallback(
    () => (errorMsg? `${formClassName} md:px-8 md:pt-8` : `${formClassName} md:p-8`),
    [errorMsg],
  )

  return (
    <form className={getFormClassName()} onSubmit={handleSubmit(onSubmit)}>
      <div className="gap-4 flex flex-col">
        {!isSmallScreen &&
          <div className="font-Poppins-Bold text-sm ">
            {welcomeBackText}
          </div>
        }
        {formFields.map(({key, ...fieldProps}) => (
          <FormField key={key} props={fieldProps} register={register} errors={errors} />
        ))}
      </div>
      <button className={getSubmitButtonClassName()} type="submit" disabled={isLoginButtonDisabled}>
        {isSubmitting ? <LoadingIcon />: loginButton}
      </button>
      {errorMsg &&
          <div className="md:mt-3 mb-3 mt-4 text-red font-Poppins-SemiBold text-2xs text-center first-letter:capitalize">{errorMsg?.toLowerCase()}</div>
      }
    </form>
  )
}

export default Login